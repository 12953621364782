<template>
  <div class="home">
    <v-app light>
      <!-- <v-toolbar color="white">
            <v-toolbar-title v-text="title"></v-toolbar-title>
      </v-toolbar>-->
      <!-- <v-main class="pt0 paddingTop0" :style="{backgroundRepeat: 'repeat' , backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/image%2Fbackground_photo.jpeg?alt=media&token=f2163f45-0f01-4c05-97b2-be4e9fa0826d')` }"> -->
      <v-main class="pt0 paddingTop0">
        <section>
          <!-- <v-layout class="ma-12 pa-6" align-center> -->
          <!-- <HomeWatchCard /> -->
          <WatchCard />
          <!-- </v-layout> -->
        </section>
        <!-- <section>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/image%2FFB_poster.jpg?alt=media&token=b791f12d-ce19-4d7c-9f0b-f0f4a0a09204"
            alt="poster"
            class="fullwidth"
          />
        </section>-->
        <section>
          <v-container grid-list-xl v-scrollanimationx>
            <v-layout column align-center justify-center class="white--text">
              <v-row>
                <v-col cols="12" md="4" lg="4" align-self="center">
                  <v-avatar size="256">
                    <v-img
                      contain
                      src="https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/image%2FSketch_PS.png?alt=media&token=a71f34dd-433a-4c4f-83e2-1b4e9177e571"
                      alt="Shop Image"
                    />
                  </v-avatar>
                </v-col>
                <v-col cols="12"  md="8" lg="8" >
                  <h1 class="pa-5 iceBlue display-1">{{content.shortIntro}}</h1>
                  <p class="pa-5 subtitle-1">{{content.shortIntroContent}}</p>
                </v-col>
              </v-row>
            </v-layout>
          </v-container>
        </section>

        <!-- <section>
          <v-parallax
            src="https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/image%2Fwatch_header.jpg?alt=media&token=6195f11a-df9e-4c9d-92ee-9ada885e56de"
            height="600"
          >
            <v-layout column align-center justify-center class="white--text">
              <img src="@/assets/wewatch_logo.png" alt="Vuetify.js" height="200" />
              <h1 class="white--text mb-2 display-1 text-center">WeWatch</h1>
              <div class="subheading mb-4 text-center">Wewatch Limited.</div>
              <v-btn
                class="mt-12"
                color="blue lighten-2"
                dark
                large
                href="/buyWatch"
              >{{content.buySellWatch}}</v-btn>
            </v-layout>
          </v-parallax>
        </section>-->

        <section>
          <v-layout column wrap class="my-12" align-center>
            <!-- <v-flex xs12 sm4 class="my-4">
              <div class="text-center" v-scrollanimationx>
                <h2 class="headline iceBlue scrollx">{{content.headline1}}</h2>
                <span class="subheading waterBlue scrollx">{{content.headline2}}</span>
              </div>
            </v-flex>-->
            <!-- <v-flex xs12> -->
            <v-container grid-list-xl v-scrollanimationy>
              <v-layout row wrap align-center>
                <v-flex xs12 md4 class="hideLink">
                  <router-link to="/buyWatch">
                    <v-card flat class="transparent">
                      <v-card-text class="text-center">
                        <v-icon x-large class="blue--text text--lighten-2">mdi-watch</v-icon>
                      </v-card-text>
                      <v-card-title primary-title class="layout justify-center">
                        <div class="headline text-center">{{content.firstT1}}</div>
                      </v-card-title>
                      <v-card-text class="px-1" v-html="content.firstT1Text"></v-card-text>
                    </v-card>
                  </router-link>
                </v-flex>
                <v-flex xs12 md4 class="hideLink">
                  <router-link to="/collectWatch">
                    <v-card flat class="transparent">
                      <v-card-text class="text-center">
                        <v-icon x-large class="blue--text text--lighten-2">mdi-watch-export</v-icon>
                      </v-card-text>
                      <v-card-title primary-title class="layout justify-center">
                        <div class="headline">{{content.firstT2}}</div>
                      </v-card-title>
                      <v-card-text class="px-1" v-html="content.firstT2Text"></v-card-text>
                    </v-card>
                  </router-link>
                </v-flex>
                <v-flex xs12 md4 class="hideLink">
                  <router-link to="/repairWatch">
                    <v-card flat class="transparent">
                      <v-card-text class="text-center">
                        <v-icon x-large class="blue--text text--lighten-2">mdi-tools</v-icon>
                      </v-card-text>
                      <v-card-title primary-title class="layout justify-center">
                        <div class="headline text-center">{{content.firstT3}}</div>
                      </v-card-title>
                      <v-card-text class="px-1" v-html="content.firstT3Text"></v-card-text>
                    </v-card>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-container>
            <!-- </v-flex> -->
          </v-layout>
        </section>

        <!-- <section>
          <v-layout column wrap class="my-12" align-center>
            <v-flex xs12 sm4 class="my-4" v-scrollanimationx>
              <div class="text-center">
                <h2 class="display-1 iceBlue">{{content.secondPart}}</h2>
                <span class="headline waterBlue">{{content.secondPartText}}</span>
              </div>
            </v-flex>
            <v-flex xs12 v-scrollanimationy>
              <v-container grid-list-xl>
                <v-layout row wrap align-center>
                  <v-flex xs12 md6 class="hideLink">
                    <router-link to="/buyWatch">
                      <v-card flat class="transparent">
                        <v-card-text class="text-center">
                          <v-icon x-large class="blue--text text--lighten-2">mdi-watch</v-icon>
                        </v-card-text>
                        <v-card-title primary-title class="layout justify-center">
                          <div class="headline text-center">{{content.secondtitle1}}</div>
                        </v-card-title>
                        <v-card-text>{{content.secondtitle1Text}}</v-card-text>
                      </v-card>
                    </router-link>
                  </v-flex>
                  <v-flex xs12 md6 class="hideLink">
                    <router-link to="/collectWatch">
                      <v-card flat class="transparent">
                        <v-card-text class="text-center">
                          <v-icon x-large class="blue--text text--lighten-2">mdi-watch-export</v-icon>
                        </v-card-text>
                        <v-card-title primary-title class="layout justify-center">
                          <div class="headline">{{content.secondtitle2}}</div>
                        </v-card-title>
                        <v-card-text>{{content.secondtitle2Text}}</v-card-text>
                      </v-card>
                    </router-link>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-flex>
          </v-layout>
        </section>-->

        <section class="lighter-section">
          <v-layout class="my-12">
            <v-row justify-center class="mt-12" v-scrollanimationx>
              <v-col cols="12">
                <h1 class="display-1 iceBlue">{{content.collectFlow}}</h1>
              </v-col>
              <v-col>
                <center>
                  <img
                    class="maxWidth959"
                    src="https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/image%2F4_step_logo.png?alt=media&token=c2f4c6c9-c107-4e2c-97b8-ba894b8a9e11"
                  />
                </center>
              </v-col>
            </v-row>
          </v-layout>
        </section>

        <section>
          <v-layout justify-center class="my-12">
            <v-row align="center" class="maxWidth100Per">
              <v-col cols="12" v-scrollanimationx>
                <h1 class="display-1 iceBlue">{{content.collectBand}}</h1>
              </v-col>
              <!-- <div > -->
              <v-col
                cols="3"
                class="ma-0 pa-0"
                align="center"
                v-for="(brand, i) in brandItems"
                :key="i"
              >
                <v-img class="inheritWidth" contain max-width :src="brand.src" :alt="brand.alt" />
              </v-col>
              <!-- </div> -->
            </v-row>
          </v-layout>
        </section>

        <section>
          <v-parallax
            src="https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/image%2Fwatch_home.jpg?alt=media&token=c8576079-556a-4f81-b6ec-572c0abbb274"
            height="380"
          >
            <v-layout column align-center justify-center>
              <div class="display-2 white--text mb-4 text-center">{{content.feedback1}}</div>
              <!-- <em>  "Awesome collection! Must visit place for Rolex fans."
              </em>-->
              <!-- <v-carousel cycle height="500" hide-delimiter-background show-arrows-on-hover>
                  <v-carousel-item
                    v-for="(item,i) in feedbacks"
                    :key="i"
                    reverse-transition="fade-transition"
                    transition="fade-transition"
                  >]
                  <em>{{item.feedback}}</em>
                  <p>{{item.name}}</p>
                  </v-carousel-item>
              </v-carousel>-->
              <!-- <v-btn class="mt-12" color="blue lighten-2" dark large href="/buyWatch">選購手錶</v-btn> -->
            </v-layout>
          </v-parallax>
        </section>
        <section>
          <v-layout column align-center justify-center>
            <v-carousel cycle height="400" hide-delimiter-background show-arrows-on-hover>
              <v-carousel-item v-for="(slide, i) in content.feedbacks" :key="i">
                <v-sheet fill-height fluid height="100%">
                  <v-row class="fill-height" align="center" justify="center">
                    <v-col cols="2"></v-col>
                    <v-col cols="8">
                      <div class="display-1">{{ slide.feedback }}</div>
                      <div class="display-1 text-right">{{ slide.user }}</div>
                    </v-col>
                    <v-col cols="2"></v-col>
                  </v-row>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </v-layout>
        </section>
        <!-- </v-parallax> -->
        <!-- </section> -->
        <!-- <section>
          <v-parallax
            src="https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/image%2Fformal_wear.jpg?alt=media&token=292fa6fd-7dc8-4de0-933a-f037feec1b5d"
            height="380"
          >
            <v-layout column align-center justify-center>
              <div
                class="display-2 white--text mb-4 text-center"
                v-scrollanimationy
              >{{content.slogan1}}</div>
              <div class="headline white--text mb-4 text-center">{{content.slogan2}}</div>
              <em>{{content.slogan3}}</em>
              <v-btn
                class="mt-12"
                color="blue lighten-2"
                dark
                large
                href="/buyWatch"
              >{{content.firstBtn}}</v-btn>
            </v-layout>
          </v-parallax>
        </section>-->
        <section class="lighter-section">
          <v-container grid-list-xl>
            <v-layout row wrap justify-center>
              <!-- <v-flex xs12 sm4>
                <v-card flat class="transparent">
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline">Company info</div>
                  </v-card-title>
                  <v-card-text>
                    Wewatch is a Hong Kong-based lux corner for both local and overseas enthusiasts.

                    We believe in the aesthetic and investment values of luxury products and provide an alternative purchase channel of authentic luxury timepieces and leather goods, both brand-new and pre-owned.  All items are friendly priced, well selected and properly maintained as we also provide maintenance services.

                    Customers are welcome to trade in or consign their preloved items with us at the best price.

                    News, knowledge, and history of our products are shared on fanpages and newsletters.
                  </v-card-text>
                </v-card>
              </v-flex>-->
              <v-flex>
                <v-card flat class="transparent my-3 mx-12 px-12">
                  <v-card-title primary-title class="layout justify-center" v-scrollanimationx>
                    <div class="headline scrollx">{{content.contactUs}}</div>
                  </v-card-title>
                  <v-card-text>{{content.contactUsTitle}}</v-card-text>
                  <v-list class="transparent" v-scrollanimationy>
                    <v-list-item class="transparent">
                      <v-list-item-action>
                        <v-icon class="blue--text text--lighten-2">mdi-phone</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <div>(+852) 2522 0123</div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="py-0 my-0 transparent">
                      <v-list-item-action class="py-0 my-0">
                        <v-icon class="blue--text text--lighten-2">mdi-map-marker</v-icon>
                      </v-list-item-action>
                      <v-list-item-content class="py-0 my-0">
                        <div>{{content.address}}</div>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item class="transparent">
                      <v-list-item-action>
                        <v-icon class="blue--text text--lighten-2">mdi-email</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <div>info@wewatch.com.hk</div>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </section>

        <!-- <v-footer color="blue darken-2">
          <v-layout row wrap align-center>
            <v-flex xs12>
              <div class="white--text ml-4">
                Made with
                <v-icon class="red--text">mdi-heart</v-icon>by
                <a class="white--text" href="https://vuetifyjs.com" target="_blank">Vuetify</a>
                and
                <a
                  class="white--text"
                  href="https://github.com/vwxyzjn"
                >Costa Huang</a>
              </div>
            </v-flex>
          </v-layout>
        </v-footer>-->
      </v-main>
    </v-app>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
// import Footer from '@/components/Footer.vue'

// import HomeWatchCard from '@/components/HomeWatchCard.vue'
import WatchCard from '@/components/WatchCard.vue'

export default {
  name: 'home',
  data () {
    return {
      content: {},
      feedbacks: [
        {
          feedback: '"Awesome collection! Must visit place for Rolex fans."',
          user: 'Chris Wong',
          source: ''
        },
        {
          feedback: '"Good stuff and good services"',
          user: 'David Yueng',
          source: ''
        }
      ],

      brandItems: [
        {
          src:
            'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/brandIcon%2Fbrand_Rolex.webp?alt=media&token=2a9fd69b-b262-4b0c-8a01-e66dd928a3a3',
          alt: 'Rolex'
        },
        {
          src:
            'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/brandIcon%2Fbrand_patex_philippe.jpg?alt=media&token=847f53a6-28fd-4b7a-b172-b73a7ab1e68b',
          alt: 'Patek Philippe'
        },
        {
          src:
            'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/brandIcon%2Fbrand_audemars_piguet.jpg?alt=media&token=162c5e79-2271-4c46-a200-26d57d55c96e',
          alt: 'Audemars Piguet'
        },
        {
          src:
            'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/brandIcon%2Fbrand_tudor.jpg?alt=media&token=f42105e1-f69d-49ee-8f09-4e692f8b6133',
          alt: 'Tudor'
        },
        {
          src:
            'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/brandIcon%2Fbrand_OMEGA.png?alt=media&token=aa4c2ef6-4725-4a54-8a72-535d65335d01',
          alt: 'OMEGA'
        },
        {
          src:
            'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/brandIcon%2Fbrand_officine_panerai.jpg?alt=media&token=aabfde40-dbcc-490c-b2d7-eb66731ca097',
          alt: 'Officine Panerai'
        },
        {
          src:
            'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/brandIcon%2Fbrand_IWC.png?alt=media&token=74cc601d-d772-4eb5-884d-da27bf494c18',
          alt: 'IWC'
        },
        {
          src:
            'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/brandIcon%2Fbrand_Cartier.webp?alt=media&token=6e985869-47f5-4f1d-af2f-867b369c1e4a',
          alt: 'Cartier'
        }
      ]
    }
  },
  components: {
  //   // HomeWatchCard,
    WatchCard
  },
  beforeCreate () {
    localStorage.currentPage = 'home'
  },
  mounted () {
    if (localStorage.language !== 'en') {
      this.content = {
        shortIntro: '關於我們',
        shortIntroContent:
          'WeWatch在香港註冊，專門本地及海外顧客提供原裝正版一手及二手的名貴鐘錶。我們相信奢侈品極具欣賞價值和保值潛力，精心挑選所有貨品，小心檢查及保養後，以相宜的價錢出售，為顧客提供最佳選擇。我們並誠意以最高價錢收購二手鐘錶及皮具，讓舊物流轉「釋放價值」。我們會定期在博客更新貨品消息、小知識及當中的小故事，多謝大家關注！',
        buySellWatch: '買賣手錶',
        // headline1: 'WeWatch專注為你 , 挑選適合你的手錶',
        // headline2:
        //   '在香港註冊，專門為本地及海外顧客提供原裝正版一手及二手的名貴鐘錶。',

        // firstBtn: '選購手錶',
        collectFlow: '網上報價流程',
        collectBand: '收購品牌',
        contactUs: '聯絡我們',
        contactUsTitle: '如果對買錶有任何問題,歡迎隨時聯絡我們',
        address: '旺角廣華街仁安大廈地鋪8號A舖',
        // slogan1: '"對於懂得去愛的人來說，時間是永恆的。"',
        // slogan2: '"For those who love, time is eternal"',
        // slogan3: '- William Shakespeare (莎士比亞) -',
        feedback1: '用戶評價',
        feedbacks: [
          // {
          //   feedback: '"用心服務，價錢合理"',
          //   user: 'David Yueng',
          //   source: ''
          // },
          // {
          //   feedback: '"店主好專業，介紹完，終於買到我第一隻嘅勞力士"',
          //   user: 'Candy Wong',
          //   source: ''
          // },
          {
            feedback:
              '"Wewatch可以用whatsapp報價，好方便快捷。 出既價錢都好合理，比其它鋪頭高"',
            user: 'David Chan',
            source: ''
          },
          {
            feedback: '"店員好好禮貌，好認真講解"',
            user: 'Cindy Wong',
            source: ''
          },
          {
            feedback: '"呢度買錶價錢唔貴，又有實物睇，唔需要上網買錶啦"',
            user: 'Tony Cheung',
            source: ''
          },
          {
            feedback:
              '"換錶帶有好多款揀，店員即時係面前換令我得好放心。速度快，收費也都唔貴"',
            user: 'Ken Lee',
            source: ''
          }
        ],
        firstT1: '選購名錶',
        firstT1Text: '我們精選世界各地全新二手名錶，<br>歡迎於網上或到店查詢',
        firstT2: '名錶回收',
        firstT2Text:
          '我們提供方便快捷的線上報價服務，<br>以高價回收各款名錶，為您的閑置<br>名錶重新釋放價值',
        // firstT3: '玩錶心得',
        // firstT3Text:
        //   '手錶知識博大精深，我們會定期和大家<br>分享有用既知識，與各位錶迷一起成長',
        firstT3: '名錶維修',
        firstT3Text:
          'WeWatch提供專業維修及服務團隊<br>會致力為客人排解疑難'
        // secondPart: '手錶樣式',
        // secondPartText: '一手及二手的名貴鐘錶',
        // secondtitle1: '全新名錶',
        // secondtitle1Text:
        //   '我們相信奢侈品極具欣賞價值和保值潛力，精心挑選所有貨品，小心檢查及保養後，以相宜的價錢出售，為顧客提供最佳選擇。',
        // secondtitle2: '再售名錶',
        // secondtitle2Text:
        //   '我們並誠意以最高價錢收購二手鐘錶及皮具，讓舊物流轉「釋放價值」。',
      }
    } else {
      this.content = {
        shortIntro: 'About Us',
        shortIntroContent:
          'Wewatch is a Hong Kong-based lux corner for both local and overseas enthusiasts. We believe in the aesthetic and investment values of luxury products and provide an alternative purchase channel of authentic luxury timepieces and leather goods, both brand-new and pre-owned.  All items are friendly priced, well selected and properly maintained as we also provide maintenance services.  Customers are welcome to trade in or consign their preloved items with us at the best price.  News, knowledge, and history of our products are shared on fanpages and newsletters.',
        buySellWatch: 'More',
        // headline1:
        //   'WeWatch focus on your needs. We only provide the watches suitable for you',
        // headline2:
        //   'WeWatch is a registered company in Hong Kong which provides authorized new watch and pre-owned watch to people in Hong Kong and overseas.',
        // firstBtn: 'More',

        collectFlow: 'Processes of Trading in Watches',
        collectBand: 'Trade In Brands',

        contactUs: 'Contact Us',
        contactUsTitle: 'Any question about watches, feel free to contact us',
        address:
          'Shop 8, G/F, Yan On Building, 1st Kwong Wa Street, Mong Kok, Hong Kong',
        // slogan1: '"For those who love, time is eternal"',
        // slogan2: '對於懂得去愛的人來說，時間是永恆的。"',
        // slogan3: '- William Shakespeare -',
        feedback1: 'Customer Reviews',
        feedbacks: [
          {
            feedback: '"Awesome collection! Must visit place for Rolex fans."',
            user: 'Chris Wong',
            source: ''
          },
          {
            feedback: '"Good stuff and good services"',
            user: 'David Yueng',
            source: ''
          }
        ],
        firstT1: 'New Watch',
        firstT1Text:
          'We believes most luxury watches have valuable and have potential to have higher value in the future',
        firstT2: 'Pre-owned Watch',
        firstT2Text:
          'We purchase pre-owned watches and sell them with their own stories to the one who know their values',
        // firstT3: 'Blog',
        // firstT3Text:
        // 'We upload articles periodly about the story about watches and new watches in the shop',
        firstT3: 'Repair',
        firstT3Text:
          'We provides repirement service and solve your watch\'s problem'
        // secondPart: 'Type of watches',
        // secondPartText: 'We provides New Watch and Pre-owned Watch',
        // secondtitle1: 'New Watch',
        // secondtitle1Text:
        //   'We believes most luxury watches have valuable and have potential to have higher value in the future',
        // secondtitle2: 'Pre-owned Watch',
        // secondtitle2Text:
        //   'We purchase pre-owned watches and sell them with their own stories to the one who know their values'
      }
    }
  }
  // components: {
  //   // HelloWorld,
  //   // Footer,
  //   // WatchCard
  // }
}
// Credit to:
// Layout
//  Vue layout by Costa Huang
// Photo
//   Watch Photo by Alessandro Cavestro on Unsplash
//   Watch Photo by Daniel Fazio on Unsplash
//   Watch Photo by Hunters Race on Unsplash
//   Watch Photo by Jeremy Beadle on Unsplash
</script>
<style scoped>
.paddingTop0 {
  padding-top: 0px;
}
</style>
