import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
// import About from '@/views/About.vue'
// // import Watch from '@/views/Watch.vue'
// import CollectWatch from '@/views/CollectWatch.vue'
// import BuyWatch from '@/views/BuyWatch.vue'
// import Blog from '@/views/Blog.vue'
// import ContactUs from '@/views/ContactUs.vue'
// import RepairWatch from '@/views/RepairWatch.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
    // },
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }, {
    path: '/about',
    name: 'about',
    // component: About
    component: () => import(/* webpackChunkName: "About" */ '@/views/About.vue')
  // }, {
  //   path: '/watch',
  //   name: 'watch',
  //   component: Watch
  }, {
    path: '/collectWatch',
    name: 'collectWatch',
    // component: CollectWatch
    component: () => import(/* webpackChunkName: "CollectWatch" */ '@/views/CollectWatch.vue')

  }, {
    path: '/buyWatch',
    name: 'buyWatch',
    // component: BuyWatch
    component: () => import(/* webpackChunkName: "BuyWatch" */ '@/views/BuyWatch.vue')

  }, {
    path: '/repairWatch',
    name: 'repairWatch',
    // component: RepairWatch
    component: () => import(/* webpackChunkName: "RepairWatch" */ '@/views/RepairWatch.vue')

  }, {
    path: '/blog',
    name: 'blog',
    // component: Blog
    component: () => import(/* webpackChunkName: "Blog" */ '@/views/Blog.vue')

  }, {
    path: '/contactUs',
    name: 'contactUs',
    // component: ContactUs
    component: () => import(/* webpackChunkName: "ContactUs" */ '@/views/ContactUs.vue')
  }
]

// const router = new VueRouter({
//   routes
// })

// export default router

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
