<!-- <template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img alt="Vuetify Logo" class="shrink mr-2" contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png" transition="scale-transition" width="40" />

        <v-img alt="Vuetify Name" class="shrink mt-1 hidden-sm-and-down" contain min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png" width="100" />
      </div>

      <v-spacer></v-spacer>

      <v-btn href="https://github.com/vuetifyjs/vuetify/releases/latest" target="_blank" text>
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-content>
      <WatchCard />
    </v-content>
    <Footer />
  </v-app>
</template> -->

<template>
  <v-app id="app">
    <!-- <Header /> -->
    <v-navigation-drawer v-model="drawer" app clipped temporary>
      <v-list dense class="hideLink">
        <!-- <router-link to="/" > -->
        <v-list-item link @click="clickElement('homePage')">
          <v-list-item-action>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="text-body-1">
              {{content.textHome}}
              <!-- <router-link to="/">Home</router-link> -->
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- </router-link> -->
        <!-- <router-link to="/buyWatch" > -->
        <v-list-item link @click="clickElement('buyWatchPage')">
          <v-list-item-action>
            <v-icon>mdi-watch</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="text-body-1">{{content.textBuy}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- </router-link> -->
        <!-- <router-link to="/collectWatch"> -->
        <v-list-item link @click="clickElement('collectWatchPage')">
          <v-list-item-action>
            <v-icon>mdi-watch-import</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="text-body-1">{{content.textSell}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link @click="clickElement('repairWatchPage')">
          <v-list-item-action>
            <v-icon>mdi-tools</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="text-body-1">{{content.textRepair}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- </router-link> -->
        <!-- <router-link to="/blog" > -->
        <v-list-item link @click="clickElement('blogPage')">
          <v-list-item-action>
            <v-icon>mdi-post</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="text-body-1">{{content.textBlog}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- </router-link> -->
        <!-- <router-link to="/contactUs" > -->
        <v-list-item link @click="clickElement('contactUsPage')">
          <v-list-item-action>
            <v-icon>mdi-account-box</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="text-body-1">{{content.textContact}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- </router-link> -->
        <v-list-item @click="changeLang">
          <v-list-item-action>
            <v-icon>fa-language</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="text-body-1">中文/English</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app clipped-left>
      <!-- hide-on-scroll -->
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-toolbar-title class="lightBlack">
        <v-tabs align-with-title class="inheritColor" id="headerToolbarTabs">
          <!-- background-color: inherit; -->
          <v-img
            class="inheritColor"
            @click="clickElement('homePage')"
            max-width="95"
            contain
            src="@/assets/wewatch_logo_OL_white.png"
            alt="WeWatch"
          />

          <v-tab class="d-none d-sm-flex">
            <router-link class="navLink" to="/" id="homePage">{{content.textHome}}</router-link>
          </v-tab>
          <v-tab class="d-none d-sm-flex pa-0">
            <!-- <router-link
              class="navLink"
              to="/buyWatch"
              @click.native="disableDialog"
              id="buyWatchPage"
            >{{content.textBuy}}</router-link>-->

            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="pa-0 ma-0 inheritColor fullwidth">
                  <router-link
                    class="navLink"
                    to="/buyWatch"
                    @click.native="disableDialog"
                    id="buyWatchPage"
                  >{{content.textBuy}}</router-link>
                  <v-icon v-bind="attrs" v-on="on" style="background-color: inherit;">mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in categoryItems"
                  :key="index"
                  @click="updateSearchList(item.title)"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-tab>
          <v-tab class="d-none d-sm-flex">
            <router-link
              class="navLink"
              to="/collectWatch"
              id="collectWatchPage"
            >{{content.textSell}}</router-link>
          </v-tab>
          <v-tab class="d-none d-sm-flex">
            <router-link
              class="navLink"
              to="/repairWatch"
              id="repairWatchPage"
            >{{content.textRepair}}</router-link>
          </v-tab>
          <v-tab class="d-none d-sm-flex">
            <router-link class="navLink" to="/blog" id="blogPage">{{content.textBlog}}</router-link>
          </v-tab>
          <v-tab class="d-none d-sm-flex">
            <router-link class="navLink" to="/contactUs" id="contactUsPage">{{content.textContact}}</router-link>
          </v-tab>

        </v-tabs>
        <!-- <v-avatar>
          <img src="@/assets/logo_e.jpg" alt="WeWatch" />
        </v-avatar>-->
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div id="headerComboboxParentId" @click="onChangeHandler">
          <!-- style="color: #FFF !important;" -->
          <!-- class="pt-0 mt-0" -->
          <!-- @keyup="comboboxOnChangeHandler" @paste="comboboxOnChangeHandler" -->
        <v-combobox
          id="headerComboboxId"
          v-model="searchWords"
          :items="searchItems"
          label="search a watch"
          append-icon="fas fa-search"
          multiple
          chips
          clearable
          :filter="changeFilter"
          style="background-color:inherit"
        >
        </v-combobox>
      </div>
      <div class="d-none d-sm-flex">
        <!-- :color="social.color" -->
        <v-btn
          v-for="(social, i) in socials"
          :key="i"
          class="red--white"
          :href="social.link"
          fab
          icon
          small
        >
          <v-img
            class="inheritColor"
            v-if="social.extraImage"
            aspect-ratio="1"
            max-width="50"
            max-height="50"
            :src="social.extraImage"
          ></v-img>
          <v-icon v-else>{{ social.icon }}</v-icon>
        </v-btn>
        <v-btn fab icon small>
          <v-icon @click="changeLang">fa-language</v-icon>
        </v-btn>
      </div>
      <!-- <template v-slot:extension>
        <v-tabs align-with-title>
          <v-tab>
            <router-link class="navLink" to="/">{{content.textHome}}</router-link>
          </v-tab>
          <v-tab>
            <router-link class="navLink" to="/buyWatch">{{content.textBuy}}</router-link>
          </v-tab>
          <v-tab>
            <router-link class="navLink" to="/collectWatch">{{content.textSell}}</router-link>
          </v-tab>
          <v-tab>
            <router-link class="navLink" to="/blog">{{content.textBlog}}</router-link>
          </v-tab>
          <v-tab>
            <router-link class="navLink" to="/contactUs">{{content.textContact}}</router-link>
          </v-tab>
        </v-tabs>
      </template>-->
    </v-app-bar>
    <v-main class="fullWidth">
      <div>
        <!-- <v-container class="fill-height" fluid> -->
        <!-- <v-row justify="center"> -->
        <router-view />
        <!-- </v-row> -->
        <!-- </v-container> -->
      </div>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
// import Header from '@/components/Header'
import Footer from '@/components/Footer'
// import { mapState, mapGetter, mapActions } from 'vuex'
import { mapActions } from 'vuex'

export default {
  name: 'app',
  components: {
    // Header,
    Footer
  },
  data: () => ({
    categoryItems: [
      { title: 'Brand New Rolex' },
      { title: 'Vintage Rolex' },
      { title: 'Daytona' },
      {
        title: 'Submariner/ Sea-Dweller/ Yacht Master'
      },
      { title: 'GMT-Master/ Explorer' },
      {
        title: 'Airking/ Milgauss/ Sky Dweller'
      },
      { title: 'Datejust/ Daydate' },
      { title: 'Other Brands' }
    ],
    drawer: null,
    language: 'zh',
    content: {},
    socials: [
      {
        icon: 'fab fa-whatsapp',
        color: 'green lighten-3',
        link:
          'https://wa.me/85268819431/?text=' +
          encodeURI('我想揾一隻適合我嘅手錶!')
      },
      {
        icon: 'fas fa-camera-retro',
        color: 'orange lighten-3',
        extraImage:
          'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2FcarousellIcon3.png?alt=media&token=4e4cb7d4-337a-48df-9b45-aa1361e6c69e',
        // 'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2FcarousellIcon2.png?alt=media&token=26e50ca6-557b-4360-8afd-7c3b081347a1',
        link: 'https://hk.carousell.com/wewatch'
      },
      {
        icon: 'fab fa-instagram',
        color: 'red lighten-3',
        link: 'https://www.instagram.com/wewatchhk/'
      },
      {
        icon: 'fab fa-facebook',
        color: 'blue',
        link: 'https://www.facebook.com/wewatchhk/'
      }
    ],
    searchItems: [
      'Rolex',
      // 'Patek',
      // 'AP',
      // 'Tudor',
      // 'Omega',
      // 'Panerai',
      // 'IWC',
      // 'Cartier',
      'Others',
      'Brand New Rolex',
      'Vintage Rolex',
      'Daytona',
      'Submariner/ Sea-Dweller/ Yacht Master',
      'GMT-Master/ Explorer',
      'Airking/ Milgauss/ Sky Dweller',
      'Datejust/ Daydate'
      // 'Other Brands'
    ],
    currentPage: ''
  }),
  computed: {
    searchWords: {
      get () {
        return this.$store.state.globalSearchWords
      },
      set (value) {
        this.replaceGlobalSearchWords(value)
      }
    }
  },
  created () {
    this.$vuetify.theme.dark = true
  },
  mounted () {
    if (localStorage.language) {
      this.language = localStorage.language
    }
    if (localStorage.language !== 'en') {
      this.content = {
        textHome: '首頁',
        textBlog: '玩錶心得',
        textBuy: '選購名錶',
        textSell: '名錶回收',
        textRepair: '名錶維修',
        textContact: '聯絡我們'
      }
    } else {
      this.content = {
        textHome: 'Home',
        textBlog: 'Blogs',
        textBuy: 'Buy Watches',
        textSell: 'Sell/Trade in',
        textRepair: 'Repair',
        textContact: 'Contact Us'
      }
    }
    if (localStorage.currentPage) {
      this.currentPage = localStorage.currentPage
    }
  },

  methods: {
    ...mapActions(['replaceGlobalSearchWords']),
    ...mapActions(['addWordToSearchList']),

    updateSearchList (oneWord) {
      this.addWordToSearchList(oneWord)
    },

    changeLang () {
      if (this.language === 'zh') {
        localStorage.language = 'en'
      } else {
        localStorage.language = 'zh'
      }
      this.language = localStorage.language
      // console.log(this.language)
      location.reload()
    },
    clickElement (elementId) {
      document.getElementById(elementId).click() // Click on the checkbox
    },
    disableDialog () {
      localStorage.dialog = false
    },
    changeFilter () {
      // console.log('localStorage.currentPage')
      // console.log(localStorage.currentPage)
      // if (localStorage.currentPage === 'home') {
      //   this.$router.push('/buyWatch')
      // }
    },
    onChangeHandler () {
      // console.log('localStorage.currentPage')
      // console.log(localStorage.currentPage)
      if (localStorage.currentPage === 'home') {
        this.$router.push('/buyWatch')
      }
    }
    // comboboxOnChangeHandler () {
    //   console.log('this.currentPage')
    //   console.log(this.currentPage)
    //   let vm = this
    //   setTimeout(function () {
    //     if (vm.$refs.numberComboBox) {
    //       vm.comboBoxModel = vm.$refs.numberComboBox.internalSearch
    //       vm.onChangeHandler()
    //     }
    //   })
    // }
  }
}
// <link href="https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css" rel="stylesheet">
</script>

<style>
#app {
  /* font-family: "Avenir", Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #806868;
}

#nav {
  padding: 30px;
}
*.theme--dark,
*.theme--dark.v-sheet,
*.theme--dark.v-application,
*.theme--dark.v-navigation-drawer {
  background: #000000;
}
nav.theme--dark.v-navigation-drawer {
  background: #000000;
}
.theme--dark.v-tabs > div.v-tabs-bar {
  background: inherit;
}
div.theme--dark.v-application {
  background: #000000;
}
i.theme--dark {
  background: inherit;
}
.v-list.v-sheet {
  background: inherit;
}

.v-btn {
  background: inherit;
}
.v-timeline,
.v-list-item {
  background: inherit;
}
.v-timeline-item,
.v-item-group.theme--dark {
  background: inherit;
}
.hideLink a {
  text-decoration: none;
}
.white {
  color: #f1f1f2;
}
.gray {
  color: #babcbe;
}
.iceBlue {
  color: #a1d6e2;
}
.waterBlue {
  color: #1995ad;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.fullwidth {
  width: 100%;
}

*,
div.v-application {
  font-family: "EnglishVersion", "SimHei", "Roboto", sans-serif;
}

@font-face {font-family: "SimHei";
    src: url("https://db.onlinewebfonts.com/t/090ec3fbb2be3c7b36967f0bda8e0964.woff2") format("woff2"), /* chrome firefox */
}
@font-face {
  font-family: "EnglishVersion";
  src: local("sans-serif");
  unicode-range: U+003A-007F;
}

/* Trebuchet MS */
/* @font-face {
  font-family: "EnglishVersion";
  src: url("//db.onlinewebfonts.com/t/03e852a9d1635cf25800b41001ee80c7.woff")
    format("woff");
  unicode-range: U+003A-007F;
} */

div.v-application .display-4,
div.v-application .display-3,
div.v-application .display-2,
div.v-application .display-1,
div.v-application .headline,
div.v-application .title {
  font-family: "EnglishVersion", "SimHei", "Roboto", sans-serif !important;
}

.before-enter-x {
  opacity: 0;
  transform: translateY(100px);
  transition: all 1s cubic-bezier(0.41, 0.01, 0.57, 1.61);
}
.enter-x {
  opacity: 1;
  transform: translateY(0px);
}

.before-enter-y {
  opacity: 0;
  transform: translateY(100px);
  transition: all 2s ease-out;
}
.enter-y {
  opacity: 1;
  transform: translateY(0px);
}
.image800Width {
  width: 100%;
  max-width: 800px;
}
.maxWidth959 {
  width: 100%;
  max-width: 959px;
}
.dark-section {
  background-color: #303030;
}
.lighter-section {
  background-color: #444444ff;
}
.v-application .navLink {
  color: #ffffff;
  text-decoration: none;
}
.showEnter {
  white-space: pre-line;
}
.inheritWidth {
  width: 100%;
  height: auto;
  max-width: 100%;
}
.maxWidth800 {
  max-width: 800px;
}
.maxWidth100Per {
  max-width: 100%;
}
.inheritColor,
div.inheritColor {
  background-color: inherit;
}
.lightBlack {
  background-color: #272727;
}
.biggerText {
  font-size: 1.2em;
}
.fullWidth {
  width: 100%;
}
#headerComboboxParentId label , #headerComboboxParentId button {
  background-color: inherit;
}

#headerComboboxParentId .v-text-field__details{
  height: 0px;
  min-height: 0px;
}
#headerToolbarTabs .v-slide-group__prev.v-slide-group__prev--disabled {
    width: 0px;
    min-width: 0px;
    overflow: hidden;
    flex: 0px;
}
</style>

<!-- <script>
// import HelloWorld from './components/HelloWorld'
import WatchCard from '@/components/WatchCard'
import Footer from '@/components/Footer'

export default {
  name: 'App',

  components: {
    // HelloWorld,
    WatchCard,
    Footer
  },

  data: () => ({
    //
  })
}
</script> -->
