import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    globalSearchWords: []
  },
  mutations: {
    addWordToSearchList (state, oneWord) {
      state.globalSearchWords.push(oneWord)
    },
    replaceGlobalSearchWords (state, allWords) {
      state.globalSearchWords = allWords
    }

  },
  actions: {
    addWordToSearchList (context, oneWord) {
      if (oneWord && !context.state.globalSearchWords.includes(oneWord)) {
        context.commit('addWordToSearchList', oneWord)
      }
    },
    replaceGlobalSearchWords (context, allWords) {
      context.commit('replaceGlobalSearchWords', allWords)
    }
  },
  modules: {
  }
})
