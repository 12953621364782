import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import ScrollAimationY from './directives/scrollanimationy'
import ScrollAimationX from './directives/scrollanimationx'
import ZoomOnHover from 'vue-zoom-on-hover'

Vue.use(ZoomOnHover)
Vue.directive('scrollanimationy', ScrollAimationY)
Vue.directive('scrollanimationx', ScrollAimationX)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
