import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
  iconfont: 'fa' || 'mdi',
  theme: {
    themes: {
      // light: {
      //   primary: colors.purple,
      //   secondary: colors.grey.darken1,
      //   accent: colors.shades.black,
      //   error: colors.red.accent3,
      // },
      dark: {
        primary: colors.shades.black
      }
    }
  }
})
