<template>
  <div>
    <v-row>
      <v-col cols="12" sm="3" class="pl-8" v-if="currentPage !== 'home'">
        <v-row
          class="d-none d-sm-flex"
          v-for="(categoryName, categoryId) in categoryItems"
          :key="categoryId"
        >
          <!-- <v-switch color="blue" v-model="searchWords" :label="categoryName" :value="categoryName"></v-switch> -->
          <v-checkbox
            color="blue"
            v-model="searchWords"
            :label="categoryName"
            :value="categoryName"
          ></v-checkbox>
          <!-- <v-switch   color="blue" v-model="searchWords" label="Jacob" value="Jacob"></v-switch> -->
        </v-row>
      </v-col>
      <v-col cols="12" :sm="specialWidth">
        <!-- <input type="text" v-model="searchWord" placeholder="search a watch" /> -->
        <!-- <v-autocomplete v-model="searchWord" :items="searchItems" dense  label="Filled"></v-autocomplete> -->
        <div class="px-4" v-show="currentPage !== 'home'">
          <v-row class="justify-end d-none">
            <v-col md="4" class="pt-0">
              <!-- <v-icon class="pa-2">fas fa-search</v-icon> -->
              <!-- style="color: inherit !important;" -->
              <v-combobox
                style="color: #FFF !important;"
                id="comboboxColorId"
                class="pt-0 mt-0"
                v-model="searchWords"
                :items="searchItems"
                label="search a watch"
                append-icon="fas fa-search"
                multiple
                chips
                clearable
                :filter="changeFilter"
              ></v-combobox>
              <!-- :change="changeFilter" -->
            </v-col>
          </v-row>
        </div>
        <v-card flat tile>
          <!-- <div>{{ watches }}</div> -->
          <!-- <div><img id="myimg" src="" alt=""></div> -->
          <!-- <v-toolbar color="cyan" dark>
            <v-app-bar-nav-icon></v-app-bar-nav-icon>

            <v-toolbar-title>Application</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon>
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-toolbar>-->
          <!-- <div>
      <v-container v-for="(watch, watchId) in watches" :key="watchId" class="grey lighten-4" fluid>
        <img :src="watch.image" />
        <h1>{{ watch.name }}</h1>
        <p>description: {{ watches.description }}</p>
      </v-container>
          </div>-->

          <v-container class="lighten-4" fluid>
            <!-- v-for="type in types" :key="type" -->
            <!-- <v-subheader>{{ type }}</v-subheader> -->

            <v-row>
              <!-- <v-spacer></v-spacer> -->
              <v-col
                v-for="(watch, watchId) in displayingWatchs"
                :key="watchId"
                cols="12"
                sm="6"
                md="3"
              >
                <v-hover v-slot:default="{ hover }">
                  <v-card @click="updateParenet(watchId)">
                    <v-img class="imgRotate" :src=" watch.previewImages && watch.previewImages[0] ? watch.previewImages[0] : watch.images[0]" height="300px">
                      <v-expand-transition>
                        <div
                          v-if="hover"
                          class="d-flex transition-fast-in-fast-out white darken-2 v-card--reveal display-1 black--text"
                          style="height: 100%;"
                        >
                          <div v-if="watch.state === 'Sale'" class="black--text">HK${{watch.price}}</div>
                          <div v-else>Sold Out</div>
                          <!-- <div class="black--text">HK${{watch.price}}</div> -->
                        </div>
                        <div
                          v-else-if="watch.state != 'Sale'"
                          class="d-flex transition-fast-in-fast-out white darken-2 v-card--reveal display-2 black--text"
                          style="height: 100%;"
                        >
                          <div>Sold Out</div>
                        </div>
                      </v-expand-transition>
                    </v-img>
                    <!-- <div v-if="watch.state === 'Sale'" class="sellIcon sale" alt="Sold">Sale</div>
                    <div v-else class="sellIcon soldOut">Sold Out</div>-->
                    <!-- <div v-if="watch.state === 'Sale'" class="sellIcon sale" alt="Sold">Sale</div> -->
                    <!-- <div v-if="watch.state === 'Sold Out'" class="sellIcon soldOut">Sold Out</div> -->
                    <v-card-title class="white justify-start height130">
                      <p class="headline black--text ellipsisOverflow showEnter">{{watch.name}}</p>
                    </v-card-title>
                    <v-card-subtitle class="white text-start height120">
                      <!-- <p style="color:black;">test:{{watch.igLink}}</p> -->
                      <p
                        class="title black--text ellipsisOverflow showEnter"
                        v-text="watch.description"
                      ></p>
                    </v-card-subtitle>
                    <!-- <v-card-text class="white text-left">
                <span class="pl-4 black--text pt-4">HK$</span>
                <span class="pl-4 black--text pt-4" v-text="watch.price"></span>
                    </v-card-text>-->
                    <!-- <a :href="'https://wa.me/85268819431/? text='+ encodeURI( '我想買手錶:' ) + encodeURI( watch.name )">testing</a> -->
                    <div v-if="currentPage !== 'home'">
                      <v-card-actions class="white justify-center">
                        <v-btn
                          v-for="(social, i) in socials"
                          :key="i"
                          :color="social.color"
                          class="red--text"
                          :href="social.icon==='fab fa-whatsapp' ? social.link + encodeURI( watch.name ) : (social.icon==='fab fa-instagram' && watch.igLink ? watch.igLink : social.link) "
                          fab
                          icon
                          small
                        >
                          <v-img
                            class="inheritColor"
                            v-if="social.extraImage"
                            aspect-ratio="1"
                            width="40"
                            height="40"
                            :src="social.extraImage"
                          ></v-img>
                          <v-icon v-else>{{ social.icon }}</v-icon>
                        </v-btn>
                      </v-card-actions>
                    </div>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-row justify="center" v-show="currentPage !== 'home'">
              <v-col cols="8">
                <v-btn  @click="loadAllRecords" v-show="!showAllRecord">Show More</v-btn>
                <v-container class="max-width" v-show="showAllRecord">
                  <v-pagination
                    class="my-4"
                    v-model="pagination.page"
                    :length="Math.ceil(filteredWatchs.length / 24)"
                  ></v-pagination>
                  <!-- @input="changePage" -->
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import firebase from '@/fb'
// var storage = firebase.storage()
import { mapActions } from 'vuex'
import router from '../router'
var db = firebase.firestore()
export default {
  // props: ['page'],
  data () {
    return {
      // types: ['Places to Be', 'Places to See'],
      // cards: ['Good', 'Best', 'Finest'],
      pagination: {
        pagelength: 1,
        page: 1
      },
      socials: [
        {
          icon: 'fab fa-whatsapp',
          color: 'green lighten-3',
          link:
            'https://wa.me/85268819431/?text=' +
            encodeURI('我想揾一隻適合我嘅手錶!')
        },
        {
          icon: 'fas fa-camera-retro',
          color: 'orange lighten-2',
          extraImage:
            'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2FcarousellIcon2.png?alt=media&token=26e50ca6-557b-4360-8afd-7c3b081347a1',

          link: 'https://hk.carousell.com/wewatch/'
        },
        {
          icon: 'fab fa-instagram',
          color: 'red lighten-3',
          link: 'https://www.instagram.com/wewatchhk/'
        },
        {
          icon: 'fab fa-facebook',
          color: 'blue',
          link: 'https://www.facebook.com/wewatchhk/'
        }
      ],
      watches: [],
      currentPage: '',
      // searchWord: '',
      // searchWords: [],
      searchItems: [
        'Rolex',
        // 'Patek',
        // 'AP',
        // 'Tudor',
        // 'Omega',
        // 'Panerai',
        // 'IWC',
        // 'Cartier',
        'Others',
        'Brand New Rolex',
        'Vintage Rolex',
        'Daytona',
        'Submariner/ Sea-Dweller/ Yacht Master',
        'GMT-Master/ Explorer',
        'Airking/ Milgauss/ Sky Dweller',
        'Datejust/ Daydate'
        // 'Other Brands'
      ],
      categoryItems: [
        'Brand New Rolex',
        'Vintage Rolex',
        'Daytona',
        'Submariner/ Sea-Dweller/ Yacht Master',
        'GMT-Master/ Explorer',
        'Airking/ Milgauss/ Sky Dweller',
        'Datejust/ Daydate'
      ],
      specialWidth: 9,
      showAllRecord: false
    }
  },
  // firestore: {
  //   watches: db.collection('watches')
  // },
  // firestore () {
  //   return {
  //     watches: db.collection('watches')
  //   }
  // },
  // beforeCreate () {
  //   console.log('localStorage.currentPage in beforeCreate')
  //   console.log(localStorage.currentPage)
  // },
  beforeMount () {
    // console.log('localStorage.currentPage in beforeCreate')
    // console.log(localStorage.currentPage)
    // },
    if (localStorage.currentPage) {
      this.currentPage = localStorage.currentPage
    }
  },
  mounted () {
    // console.log('localStorage.currentPage in mounted')
    // console.log(localStorage.currentPage)
    // if (!localStorage.pPicture) {
    //   this.updateParenet(0)
    // }
    if (this.currentPage !== 'home') {
      this.specialWidth = 9
    } else {
      this.specialWidth = 12
    }
    this.fetchDataForWatches()
  },
  beforeUpdate () {
    let tempPrioritizeArr = []
    let tempStateArr = []
    let tempOtherArr = []
    // console.log(this.watches)
    this.watches.forEach(function (oneWatch) {
      if (oneWatch.prioritize === 'true' && oneWatch.state === 'Sale') {
        tempPrioritizeArr.push(oneWatch)
      } else if (oneWatch.state === 'Sale') {
        tempStateArr.push(oneWatch)
      } else {
        if (oneWatch.state === 'Sold Out') {
          oneWatch.price = ' -- '
        }
        tempOtherArr.push(oneWatch)
      }
    })
    // for (let oneWatch in this.watches) {
    //   if (oneWatch.prioritize === 'true') {
    //     tempPrioritizeArr.push(oneWatch)
    //   } else if (oneWatch.prioritize === 'Sale') {
    //     tempStateArr.push(oneWatch)
    //   } else {
    //     tempOtherArr.push(oneWatch)
    //   }
    // }
    // console.log('tempPrioritizeArr')
    // console.log(tempPrioritizeArr)

    // console.log('tempStateArr')

    // console.log(tempStateArr)
    // console.log('tempOtherArr')
    // console.log(tempOtherArr)

    tempPrioritizeArr = tempPrioritizeArr.concat(tempStateArr)
    this.watches = tempPrioritizeArr.concat(tempOtherArr)
    // console.log(' this.watches')
    // console.log(this.watches)
  },

  // created () {
  // function compareWatch (a, b) {
  //   // Use toUpperCase() to ignore character casing
  //   const bandA = a.state.toUpperCase()
  //   const bandB = b.state.toUpperCase()
  //   let comparison = 0
  //   if (bandA > bandB) {
  //     comparison = 1
  //   } else if (bandA < bandB) {
  //     comparison = -1
  //   }
  //   return comparison
  // }
  // console.log(this.watches)
  // this.watches = this.watches.sort(compareWatch)
  // console.log(this.watches)
  // db.collection('watches')
  //   .get()
  //   .then(querySnapshot => {
  //     querySnapshot.forEach(doc => {
  //       // console.log('doc.data(): ')
  //       // console.log(doc.data())
  //       this.watches.push(doc.data())
  //       // console.log(`${doc.id} => ${doc.data()}`)
  //     })
  //   })
  // this.watches = db.collection('watches').get().catch(err => {
  //   console.log('Error getting document', err)
  // })
  // console.log('this.watches: ' + this.watches)
  // console.log(this.watches)
  //   // this unbinds any previously bound reference
  //   this.$bind('watches', db.collection('watches')).then(watches => {
  //     this.watches === watches
  //     // todos are ready to be used
  //     // if it contained any reference to other document or collection, the
  //     // promise will wait for those references to be fetched as well
  //     // you can unbind a property anytime you want
  //     // this will be done automatically when the component is destroyed
  //     // this.$unbind('todos')
  //   })
  // },
  // mounted () {
  //   storage.child('image/formal_wear.jpg').getDownloadURL().then(function (url) {
  //     // `url` is the download URL for 'images/stars.jpg'

  //     // This can be downloaded directly:
  //     // var xhr = new XMLHttpRequest();
  //     // xhr.responseType = 'blob';
  //     // xhr.onload = function(event) {
  //     //   var blob = xhr.response;
  //     // };
  //     // xhr.open('GET', url);
  //     // xhr.send();

  //     // Or inserted into an <img> element:
  //     var img = document.getElementById('myimg')
  //     img.src = url
  //   // eslint-disable-next-line handle-callback-err
  //   }).catch(function (error) {
  //     // Handle any errors
  //     console.log(error)
  //   })
  // },
  computed: {
    searchWords: {
      get () {
        return this.$store.state.globalSearchWords
      },
      set (value) {
        this.replaceGlobalSearchWords(value)
      }
    },

    //     searchWords () {
    //   return this.$store.state.globalSearchWords
    // },
    // ...mapState(['globalSearchWords']),
    // ...mapGetter(['globalSearchWords']),
    // ...mapState(['globalSearchWords']),

    displayingWatchs: function () {
      return this.filteredWatchs.slice(
        24 * (this.pagination.page - 1),
        24 * this.pagination.page
      )
    },

    filteredWatchs: function () {
      // console.log('this.searchWords')
      // console.log(this.searchWords)

      return this.watches.filter((watch) => {
        if (this.searchWords.length === 0) {
          return true
        }
        var resultBool = true
        for (var keyword of this.searchWords) {
          keyword = keyword.toLowerCase()
          var searchBool1 = false
          var searchBool2 = false
          var searchBool3 = false
          var searchBool4 = false
          var searchBool5 = false
          if (watch.name) {
            searchBool1 = watch.name.toLowerCase().match(keyword)
          }
          if (watch.brand) {
            searchBool2 = watch.brand.toLowerCase().match(keyword)
          }
          if (watch.category) {
            searchBool3 = watch.category.toString().toLowerCase().match(keyword)
          }
          if (watch.description) {
            searchBool4 = watch.description.toLowerCase().match(keyword)
          }
          if (watch.brand) {
            if (keyword === 'others' || keyword === 'other') {
              searchBool5 =
                watch.brand.toLowerCase().match('patek') ||
                watch.brand.toLowerCase().match('ap') ||
                watch.brand.toLowerCase().match('tudor') ||
                watch.brand.toLowerCase().match('omega') ||
                watch.brand.toLowerCase().match('panerai') ||
                watch.brand.toLowerCase().match('iwc') ||
                watch.brand.toLowerCase().match('cartier')
            }
          }
          // console.log('keyword' + keyword)
          // console.log('searchBool1 ' + watch.name + searchBool1)
          // console.log('searchBool2 ' + watch.brand + searchBool2)
          // console.log('searchBool3 ' + watch.category + searchBool3)
          // console.log('searchBool4 ' + watch.description + searchBool4)

          resultBool =
            resultBool &&
            (searchBool1 ||
              searchBool2 ||
              searchBool3 ||
              searchBool4 ||
              searchBool5)
        }
        return resultBool
      })
    }
  },
  methods: {
    ...mapActions(['replaceGlobalSearchWords']),
    fetchDataForWatches () {
      if (this.currentPage === 'home') {
        // setTimeout(function () {
        db.collection('watches')
          .where('state', '==', 'Sale')
          .orderBy('updateTime', 'desc')
          .limit(12)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.watches.push(doc.data())
            })
          })
        // }, 1000)
      } else {
        db.collection('watches')
          // .orderBy('state')
          .orderBy('updateTime', 'desc')
          .limit(24)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              // console.log('doc.data(): ')
              // console.log(doc.data())
              this.watches.push(doc.data())
              // console.log(`${doc.id} => ${doc.data()}`)
            })
          })
      }
    },
    updateParenet (watchId) {
      if (this.currentPage !== 'home') {
        // console.log(watchId)

        this.$parent.pPicture = this.displayingWatchs[watchId].images[0]
        this.$parent.pPictures = this.displayingWatchs[watchId].images
        this.$parent.pName = this.displayingWatchs[watchId].name
        this.$parent.pDescription = this.displayingWatchs[watchId].description
        this.$parent.pPrice = this.displayingWatchs[watchId].price
        this.$parent.pBrand = this.displayingWatchs[watchId].brand
        this.$parent.pState = this.displayingWatchs[watchId].state
        this.$parent.pIgLink = this.displayingWatchs[watchId].igLink

        // document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
        // this.clickElement()
        // document.getElementById('#watchDetailDialog').click()
        this.$parent.showDialog = true
      } else {
        localStorage.pPicture = this.watches[watchId].images[0]
        localStorage.pPictures = JSON.stringify(this.watches[watchId].images)
        localStorage.pName = this.watches[watchId].name
        localStorage.pDescription = this.watches[watchId].description
        localStorage.pPrice = this.watches[watchId].price
        localStorage.pBrand = this.watches[watchId].brand
        localStorage.pState = this.watches[watchId].state
        localStorage.pIgLink = this.watches[watchId].igLink
        localStorage.showDialog = 'true'
        router.push({
          name: 'buyWatch'
        })
      }
    },
    clickElement (elementId) {
      document.getElementById(elementId).click() // Click on the checkbox
    },
    //   getImage (name) {
    //     console.log(firebase)
    //     console.log(firebase.storage())
    //     var url = firebase.storage.child(name).getDownloadURL()
    //     console.log(url)
    //     return url
    //     //     const min = 550
    //     //     const max = 560

    //     //     return Math.floor(Math.random() * (max - min + 1)) + min
    //   }
    // changePage () {
    // }
    changeFilter (item, queryText, itemText) {
      this.pagination.page = 1
    },
    //     changeFilter () {
    //   this.pagination.page = 1
    // }
    loadAllRecords () {
      this.showAllRecord = true
      this.watches = []
      db.collection('watches')
        .orderBy('updateTime', 'desc')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.watches.push(doc.data())
          })
        })
    }
  }
}
</script>
<style >
.imgRotate {
  /* transform:rotate(90deg); */
  image-orientation: from-image;
}

.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.7;
  position: absolute;
  width: 100%;
}

.sellIcon {
  position: absolute;
  top: 0;
  left: 0;
}
.sale {
  background-color: red;
}
.soldOut {
  background-color: black;
}

.height120 {
  height: 120px;
}
.height130 {
  height: 130px;
}
.ellipsisOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
  height: 100%;
}
.v-list div.v-list-item--active {
  color: inherit !important;
}

#comboboxColorId .v-application .primary--text {
  color: #ffffff !important;
}
#comboboxColorId {
  color: #ffffff !important;
}

.v-application .v-icon.primary--text {
  color: #ffffff !important;
}
</style>
