import firebase from 'firebase/app'
import 'firebase/firestore'

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: 'AIzaSyBprFMh77ym1zoB-X6SFei0j6IFPYlU8L4',
  authDomain: 'wewatch-website.firebaseapp.com',
  databaseURL: 'https://wewatch-website.firebaseio.com',
  projectId: 'wewatch-website',
  storageBucket: 'wewatch-website.appspot.com',
  messagingSenderId: '1044673247594',
  appId: '1:1044673247594:web:c3330f170cd9fdd5e30d13',
  measurementId: 'G-ZTF3MR0R1N'
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
// firebase.analytics()
// firebase.analytics()

// const db = firebase.firestore()
// db.settings({ timestampsInSnapshots: true })

export default firebase
