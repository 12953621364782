<template>
  <div>
    <v-container class="lighten-4 pt-0" fluid>
      <!-- <v-footer dark padless> -->
      <v-card flat tile class="lighten-1 white--text text-center pt-0">
        <v-card-text class="fullwidth pt-0">
          <!-- <v-btn v-for="icon in icons" :key="icon" class="mx-4 white--text" icon>
            <v-icon size="24px">{{ icon }}</v-icon>
          </v-btn>-->
          <v-card-actions class="justify-center">
            <v-btn
              large
              v-for="(social, i) in socials"
              :key="i"
              :color="social.color"
              class="pl2 pr2 red--text"
              :href="social.link"
              fab
              icon
              small
            >
              <v-img
                v-if="social.extraImage"
                aspect-ratio="1"
                max-width="50"
                max-height="50"
                :src="social.extraImage"
              ></v-img>
              <v-icon v-else>{{ social.icon }}</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card-text>

        <v-card-text class="white--text pt-0">
          <!-- Phasellus feugiat arcu sapien, et iaculis ipsum elementum sit amet. Mauris cursus commodo interdum. Praesent ut
          risus eget metus luctus accumsan id ultrices nunc. Sed at orci sed massa consectetur dignissim a sit amet dui.-->
          <!-- Duis commodo vitae velit et faucibus. Morbi vehicula lacinia malesuada. Nulla placerat augue vel ipsum ultrices,
          cursus iaculis dui sollicitudin. Vestibulum eu ipsum vel diam elementum tempor vel ut orci. Orci varius natoque
          penatibus et magnis dis parturient montes, nascetur ridiculus mus.-->
          {{content.slogan}}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          Copyright © {{ new Date().getFullYear() }}
          <strong>WeWatch</strong>
        </v-card-text>
      </v-card>
      <!-- </v-footer> -->
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'Footer',

  data: () => ({
    // icons: [
    //   'fab fa-facebook',
    //   'fab fa-twitter',
    //   'fab fa-google-plus',
    //   'fab fa-linkedin',
    //   'fab fa-instagram'
    // ],
    content: {},
    // whatsapp, Carousell, IG, FB,  WeChat
    socials: [
      {
        icon: 'fab fa-whatsapp',
        color: 'green lighten-3',
        link:
          'https://wa.me/85268819431/?text=' +
          encodeURI('我想揾一隻適合我嘅手錶!')
      },
      {
        icon: 'fas fa-camera-retro',
        color: 'orange lighten-2',
        extraImage:
          'https://firebasestorage.googleapis.com/v0/b/wewatch-website.appspot.com/o/shop%2FcarousellIcon2.png?alt=media&token=26e50ca6-557b-4360-8afd-7c3b081347a1',

        link: 'https://hk.carousell.com/wewatch/'
      },
      {
        icon: 'fab fa-instagram',
        color: 'red lighten-3',
        link: 'https://www.instagram.com/wewatchhk/'
      },
      {
        icon: 'fab fa-facebook',
        color: 'blue',
        link: 'https://www.facebook.com/wewatchhk/'
      }
    ]
  }),
  mounted () {
    if (localStorage.language !== 'en') {
      this.content = {
        slogan: 'WeWatch專注為你 , 挑選適合你的手錶'
      }
    } else {
      this.content = {
        slogan:
          'WeWatch focus on your need and we only provide watches for your specify need'
      }
    }

    this.$parent.socials = this.socials
  }
}
</script>
